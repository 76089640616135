<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <view-history
        :visible="showHistory"
        :resource-id="resourceId"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <b-button
          id="create-button"
          variant="primary"
          :class="{
            'icon-disabled': !hasSystemSettingsActions,
          }"
          @click="hasSystemSettingsActions && create()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('New Field') }}</span>
        </b-button>
        <b-tooltip target="create-button">
          {{ hasSystemSettingsActions ? $t('Create') : $t('You dont have permission to create') }}
        </b-tooltip>
        <div>
          <prozess-search-input
            :value="search"
            :placeholder="$t('Search')"
            @input="handleSearch"
          />
        </div>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(entities)="{ item }">
            <div class="d-flex">
              <b-badge
                v-for="index of 2"
                v-show="item.entities[index - 1]"
                :key="index"
                variant="secondary"
                class="mr-1"
              >
                {{ $t($case.title(item.entities[index - 1])) }}
              </b-badge>
              <b-badge
                v-if="item.entities.length > 2"
                variant="secondary"
                class="mr-1"
              >
                +{{ item.entities.length - 2 }}
              </b-badge>
            </div>
          </template>

          <template #cell(required)="{ item }">
            <b-badge :variant="item.required ? 'danger' : 'gray'">
              {{ item.required ? `*${$t('Required')}` : $t('Optional') }}
            </b-badge>
          </template>

          <template #cell(name)="{ item }">
            <div class="d-flex">
              <div style="width: 2.2rem">
                <feather-icon
                  v-if="item.favorite"
                  icon="StarIcon"
                  size="16"
                  class="mr-1 text-secondary"
                />
              </div>
              <span style="white-space: nowrap">
                {{ item.name }}
              </span>
            </div>
          </template>

          <template #cell(fieldType)="{ item }">
            <div class="d-flex">
              <div style="width: 2.2rem">
                <feather-icon
                  :icon="customFieldTypeIcons[item.fieldType]"
                  size="16"
                  class="mr-1"
                />
              </div>
              <span style="white-space: nowrap">
                {{ $t($case.title(item.fieldType)) }}
              </span>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.id}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                @click="hasSystemSettingsActions && edit(item.id)"
              />
              <b-tooltip
                :title="hasSystemSettingsActions ? $t('Edit') : $t('You dont have permission to update')"
                :target="`edit-row-${item.id}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`history-row-${item.id}-preview-icon`"
                icon="ClockIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="toggleHistory(item.id)"
              />
              <b-tooltip
                :title="$t('View history')"
                :target="`history-row-${item.id}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${item.id}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                @click="hasSystemSettingsActions && remove(item.id)"
              />
              <b-tooltip
                :title="hasSystemSettingsActions ? $t('Deactivate') : $t('You dont have permission to deactivate fields')"
                :target="`delete-row-${item.id}-preview-icon`"
                placement="left"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import fieldManagementService from '@/services/fieldManagement'
import { customFieldTypeIcons } from '@/constants/customFieldTypes'
import listMixinFactory from '@/mixins/list'
import SidebarForm from './Form.vue'
import ViewHistory from './ViewHistory.vue'

export default {
  components: {
    SidebarForm,
    ViewHistory,
  },
  mixins: [
    listMixinFactory({
      routeName: 'custom-field-settings',
      service: fieldManagementService,
      tableColumns: [
        { key: 'name', label: 'Title', sortable: true },
        { key: 'label', label: 'Label', sortable: true },
        { key: 'fieldType', label: 'Field Type', class: ' disabled' },
        { key: 'required', label: 'Condition', class: 'text-center disabled' },
        { key: 'entities', label: 'Entities', class: 'disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),
  ],
  data() {
    return {
      customFieldTypeIcons,
      resourceId: null,
      showForm: false,
      showHistory: false,
    }
  },

  methods: {
    toggleHistory(id) {
      this.resourceId = id
      this.showHistory = true
    },
    displayPermissions(permissions) {
      return this.$lodash.groupBy(permissions, 'name')
    },
    create() {
      this.resourceId = null
      this.showForm = true
    },
    edit(id) {
      if (this.$can('Update', 'Field_Management') === false) return
      this.resourceId = id
      this.showForm = true
    },
    remove(id) {
      if (this.$can('Delete', 'Field_Management') === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to deactivate?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
      this.showHistory = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
