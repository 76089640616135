import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/core/field_management'

export default {
  ...apiHelper(API_URL),
  getHistory(id, query) {
    return API.get(`${API_URL}/${id}/history?${toQuery(query)}`)
  },
}
