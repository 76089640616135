export default {
  Company: 'company',
  Contact: 'contact',
  CompanyContactRelation: 'company_has_contact',
  Document: 'document',
  Note: 'note',
  Inquiry: 'inquiry',
  Todo: 'todo',
  Activities: 'activities',
}
