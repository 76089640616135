<template>
  <div
    tabindex="0"
    @keyup.esc="$emit('close')"
  >
    <b-sidebar
      id="form-sidebar"
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div class="form-sidebar__header d-flex justify-content-between align-items-center p-1">
          <template v-if="isCustomEntity">
            <div class="d-flex flex-column">
              <div
                class="d-flex mb-1 cursor-pointer"
                @click="close"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  class="mr-50"
                />
                <p class="m-0">
                  {{ $t('Back') }}
                </p>
              </div>
              <h1 class="form-sidebar__header-title m-0 text-uppercase">
                {{ $t(formTitle) }}
              </h1>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">
              <feather-icon :icon="formIcon" />
              <h1 class="form-sidebar__header-title m-0 ml-1 text-uppercase">
                {{ $t(formTitle) }}
              </h1>
            </div>
            <button
              class="btn btn-icon rounded-circle btn-close"
              @click="close"
            >
              <feather-icon
                icon="XIcon"
                class="tw-text-white"
              />
            </button>
          </template>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <form
            id="customFieldForm"
            ref="form"
            autocomplete="off"
            class="p-2"
            style="flex: 1"
          >
            <prozess-input
              v-model="resource.name"
              :placeholder="$t('Title')"
              icon="TypeIcon"
              field="name"
              name="name"
              :error="$hasError('name')"
              @enter="save"
            />
            <prozess-input
              v-model="resource.label"
              :placeholder="$t('Label')"
              icon="TagIcon"
              field="label"
              :error="$hasError('label')"
              @enter="save"
            />
            <prozess-input
              v-model="resource.key"
              :disabled="!!resource.id"
              :placeholder="$t('Key')"
              icon="KeyIcon"
              field="key"
              :hint="$t('The field key must be unique')"
              :error="$hasError('key')"
              @input="handleKeyInput"

              @keypress="preventSpecialChars"
              @enter="save"
            />
            <prozess-input
              v-model="resource.defaultValue"
              :placeholder="$t('Default Value')"
              icon="TypeIcon"
              field="defaultValue"
              :error="$hasError('defaultValue')"
              @enter="save"
            />
            <b-input-group
              class="input-group-merge mb-2 form-sidebar__add-option"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="GridIcon" />
              </b-input-group-prepend>
              <prozess-select
                ref="roleId"
                v-model="resource.fieldType"
                style="flex: 1"
                :placeholder="$t('Type')"
                :options="types"
                :reduce="type => type.value"
                :disabled="!!customField"
              />
              <span
                v-if="$hasError('fieldType')"
                class="invalid-feedback"
              >
                {{ $t($hasError('fieldType')) }}
              </span>
            </b-input-group>
            <template
              v-if="resource.fieldType === CustomFieldTypes.List"
            >
              <div class="form-sidebar__options">
                <div class="p-1 form-sidebar__options-header">
                  <h5>{{ $t("Options") }}</h5>
                </div>
                <div class="p-1">
                  <b-form-tags
                    v-model="resource.values"
                    input-id="tags-basic"
                  />
                </div>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <span
                  v-show="$hasError('values')"
                  class="invalid-feedback"
                  style="flex: 1"
                >
                  {{ $t($hasError('values')) }}
                </span>
                <span
                  style="flex: 2;"
                  class="field-hint"
                >{{ $t('Hit enter after typing to add a value.') }}</span>
              </div>
            </template>
            <div
              v-if="!isCustomEntity"
              class="form-sidebar__options"
            >
              <div class="p-1 form-sidebar__options-header">
                <h5>{{ $t("Add to Entities") }}</h5>
              </div>
              <div class="p-1">
                <b-form-checkbox-group v-model="resource.entities">
                  <b-form-checkbox
                    v-for="entity of entities"
                    :key="entity.label"
                    :value="entity.value"
                    class="d-flex justify-content-between"
                    @keyup.enter.native="save"
                  >
                    <p style="margin-top: 0.1rem;">
                      {{ $t(entity.label) }}
                    </p>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </div>
            <span
              v-if="$hasError('entities')"
              class="invalid-feedback"
            >
              {{ $t($hasError('entities')) }}
            </span>
            <div class="mt-1 pt-1 tw-flex tw-flex-wrap">
              <b-form-checkbox
                v-model="resource.favorite"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Favorite') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="resource.required"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Required') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-if="isCustomEntity"
                v-model="resource.searchable"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Searchable') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="resource.unique"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Unique') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-if="isCustomEntity"
                v-model="resource.partOfName"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Title') }}</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="resource.hidden"
                class="tw-w-6/12 tw-m-0 tw-mb-6"
                switch
                inline
                @keyup.enter.native="save"
              >
                <span style="font-size: 1rem">{{ $t('Hidden') }}</span>
              </b-form-checkbox>
            </div>
          </form>
          <div
            class="form-sidebar__footer d-flex justify-content-around align-items-center p-1"
          >
            <button
              class="btn btn-primary mr-1 d-flex justify-content-center align-items-center"
              type="submit"
              :disabled="saving"
              @click="save"
            >
              <b-spinner
                v-if="saving"
                small
                class="mr-1"
              />
              <feather-icon
                v-else
                :icon="saveIcon"
                class="mr-50"
              />
              <span>{{ $t(saveLabel) }}</span>
            </button>
            <b-button variant="outline-primary" class="mr-1" @click="close">
              {{ $t('Close') }}
            </b-button>
          </div>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { customFieldSchema } from '@/schema/customField'
import { preventSpecialChars } from '@/helpers/app'
import fieldManagement from '@/services/fieldManagement'
import prozessSelect from '@/@core/components/ProzessSelect.vue'
import prozessInput from '@/@core/components/ProzessInput.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import CustomFieldEntities from '@/constants/customFieldEntities'

export default {
  components: {
    prozessSelect,
    prozessInput,
  },
  props: {
    isCustomEntity: { // If the form is under Custom Entity Form
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    customField: {
      type: Object,
      default: null,
    },
    customFields: {
      type: Array,
      default: () => [],
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      CustomFieldTypes,
      CustomFieldEntities,
      formIcon: 'PlusIcon',
      resource: {},
      options: [],
      saving: false,
      loading: true,
      errors: [],
      keyFieldTouched: false,
    }
  },
  computed: {
    types() {
      return Object.keys(CustomFieldTypes).map(key => ({
        label: this.$t(key),
        value: CustomFieldTypes[key],
      }))
    },
    entities() {
      return Object.keys(CustomFieldEntities).map(key => ({
        label: key,
        value: CustomFieldEntities[key],
      }))
    },
    saveIcon() {
      return this.resourceId ? '' : 'SaveIcon'
    },
    saveLabel() {
      return this.resourceId ? 'Save Changes' : 'Save'
    },
    formTitle() {
      if (this.isCustomEntity) {
        return this.customField ? 'Edit Field On Entity' : 'Add Field To Entity'
      }
      return this.resourceId ? 'Edit Custom Field' : 'New Custom Field'
    },
  },
  watch: {
    'resource.name': function (value) {
      if (this.resource.id) return
      if (this.keyFieldTouched === false) {
        const replaceSpecialChar = value?.replace(/[^\w\s]/gi, '') ?? ''
        this.resource.key = this.$case.snake(replaceSpecialChar)
      }
    },
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          if (this.resourceId) {
            return this.getOne(this.resourceId)
          }
          if (this.isCustomEntity && this.customField) {
            this.resource = this.$lodash.cloneDeep(this.customField)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    preventSpecialChars(e) {
      return preventSpecialChars(e, data => {
        this.resource.key = data
        this.$forceUpdate()
      })
    },

    handleKeyInput() {
      this.keyFieldTouched = true
    },
    handleGroupInput() {
      this.$forceUpdate()
    },
    reset() {
      this.errors = []
      this.resource = {}
      this.loading = false
      this.saving = false
      this.keyFieldTouched = false
    },
    getOne(id) {
      this.loading = true
      fieldManagement
        .getOne(id)
        .then(res => {
          this.resource = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
    createOrUpdate(data) {
      return this.resourceId
        ? fieldManagement.update(this.resourceId, data)
        : fieldManagement.store(data)
    },
    async validate() {
      this.errors = await this.yupValidate(
        customFieldSchema({
          $t: value => this.$t(value),
          isCustomEntity: this.isCustomEntity,
          customFields: this.customFields,
        }),
        this.resource,
      )

      if (this.resource.fieldType === CustomFieldTypes.List && this.$lodash.get(this.resource, 'values', []).length === 0) {
        const index = this.errors.length === 0 ? 0 : this.errors.length - 1
        this.$set(this.errors, index, {
          field: 'values',
          defaultMessage: this.$t('Required'),
        })
      }
    },
    cleanupData() {
      Object.keys(this.resource).forEach(key => {
        if (this.resource[key] !== null && this.resource[key].toString().trim() === '') {
          delete this.resource[key]
        }
      })

      if (!this.resource.favorite) { this.resource.favorite = false }
      if (!this.resource.required) { this.resource.required = false }
      if (!this.resource.searchable) { this.resource.searchable = false }
      if (!this.resource.unique) { this.resource.unique = false }
      if (!this.resource.partOfName) { this.resource.partOfName = false }
      if (!this.resource.hidden) { this.resource.hidden = false }
    },
    async save() {
      await this.validate()
      if (this.errors.length > 0) return

      this.cleanupData()

      if (this.isCustomEntity) {
        const customFieldAlreadyExist = this.customFields
          .filter(item => {
            if (this.customField && this.customField.uuid === item.uuid) return false
            return true
          })
          .find(item => item.key === this.resource.key)

        if (customFieldAlreadyExist) {
          return this.showWarning('Field key already exist')
        }

        this.close()
        return this.$emit(this.customField ? 'updated' : 'saved', this.resource)
      }

      this.saving = true
      this.resource.type = this.resource.fieldType
      const { error } = await this.$async(this.createOrUpdate(this.resource))
      if (error) {
        this.errors = this.$loadErrors(error)
      } else {
        this.$emit('saved')
        this.close()

        if (this.resourceId) return
        setTimeout(() => this.showWarning('Once custom field is in use, you won\'t be able to edit or delete it'), 500)
      }
      this.saving = false
    },
  }, // methods
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
