import * as yup from 'yup'

const keyPattern = /^[a-z_0-9\\]*$/
export const customFieldSchema = ({ customFields, isCustomEntity, $t }) => {
  const shape = {
    name: yup.string().required().max(50, `${$t('Max')} 50`),
    label: yup.string().required().max(50, `${$t('Max')} 50`),
    key: yup.string().required()
      .min(3, `${$t('Min')} 3`)
      .max(50, `${$t('Max')} 50`)
      .matches(keyPattern, 'Invalid Characters'),
    defaultValue: yup.string().nullable().max(200, `${$t('Max')} 200`),
    fieldType: yup.string().required().transform(v => (v === null ? '' : v)),
  }

  if (!isCustomEntity) {
    shape.entities = yup.array().of(yup.string()).required().min(1)
  }

  return yup.object().shape(shape)
}

export default {
  customFieldSchema,
}
