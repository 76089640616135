<template>
  <div>
    <b-sidebar
      id="form-sidebar"
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div class="form-sidebar__header d-flex justify-content-between align-items-center p-1">
          <div class="d-flex align-items-center">
            <h1 class="form-sidebar__header-title m-0">
              {{ $t('History') }}
            </h1>
          </div>
          <button
            class="btn btn-icon rounded-circle btn-close"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              style="color: $colour--white"
            />
          </button>
        </div>
        <div
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <div
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="10"
            class="custom-field-history p-1"
            style="flex: 1"
          >
            <div
              v-for="(transaction,index) of transactions"
              :key="index"
              class="custom-field-history__row px-2 py-1 mb-1 d-flex flex-column justify-content-between tw-text-text-primary"
            >
              <span class="mb-1">{{ $displayTransaction(transaction) }}</span>
              <span class="custom-field-history__row-timestamp">{{ $d(new Date(transaction.timestamp), 'long') }}</span>
            </div>
            <div class="d-flex justify-content-center m-1">
              <b-spinner
                v-if="loading"
                variant="primary"
              />
            </div>
          </div>

          <div class="form-sidebar__footer d-flex justify-content-start align-items-center p-1">
            <b-button variant="outline-primary" class="mr-1" @click="close">
              {{ $t('Close') }}
            </b-button>
          </div>
        </div>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import fieldManagement from '@/services/fieldManagement'
import CustomFieldTypes from '@/constants/customFieldTypes'
import CustomFieldEntities from '@/constants/customFieldEntities'
import { debounce } from 'lodash'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      CustomFieldTypes,
      CustomFieldEntities,
      formIcon: 'PlusIcon',
      loading: true,
      items: [],
      currentPage: 1,
      total: 0,
    }
  },
  watch: {
    resourceId: {
      handler(id) {
        if (id) {
          return this.getHistory(id)
        }
        return this.reset()
      },
      immediate: true,
    },
    visible: {
      handler(visible) {
        if (visible === false) {
          this.reset()
        }
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      this.currentPage = 1
      this.errors = []
      this.transactions = []
      this.loading = false
      this.saving = false
      this.keyFieldTouched = false
    },
    loadMore: debounce(function () {
      if (!this.resourceId || this.transactions.length >= this.total) return
      this.getHistory(this.resourceId, this.currentPage)
    }, 200),
    getHistory(id, page = 1) {
      this.currentPage += 1
      this.loading = true
      fieldManagement
        .getHistory(id, {
          page: page - 1,
          size: 25,
        })
        .then(res => {
          this.transactions = [...this.transactions, ...res.data.pageItems]
          this.total = res.data.allItemsCount
          this.$forceUpdate()
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/custom-field-history.scss';
</style>
